<template>
  <div class="content-wrpper">
    <div class="">
        <div class="nav-wrapper fixed-top">
            <combme-navigation></combme-navigation>
        </div>
        <div class="row background-grey" id="home">
            <!-- div -->
            <div class="col-lg-6 col-xs-12 col-sm-12 custom-container">
                <p class="first-line">{{ $t('organizing') }}</p>
                <p class="second-line">{{ $t('madeEasy') }}</p>
                <div class="third-line-div">
                    <p class="third-line">{{ $t('comb') }}<span>{{ $t('me') }}</span></p>
                    <img class="data-security-img" src="../assets/images/software-hosted-in-germany.png" alt="">
                </div>
                <img class="header-line" src="../assets/images/header_line.png" alt="">
                <img class="hero-grid" src="../assets/images/hero_grid.png" alt="">
                <p class="available-in-stores">{{ $t('avalilableInSores') }}</p>
                <div class="store-wrapper">
                    <a v-if="this.$i18n.locale == 'en'" target="_blank" href="https://apps.apple.com/hr/app/combme/id1499095253">    
                        <img class="app-store" src="../assets/images/app_store.png" alt="app store">
                    </a>
                    <a v-if="this.$i18n.locale == 'de'" target="_blank" href="https://apps.apple.com/de/app/combme/id1499095253">
                        <img class="app-store" src="../assets/images/app_store_ger.png" alt="app store">
                    </a>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.combme.app">
                        <img v-if="this.$i18n.locale == 'en'" src="../assets/images/google_play.png" alt="google play">
                    </a>
                    <a v-if="this.$i18n.locale == 'de'" target="_blank" href="https://play.google.com/store/apps/details?id=com.combme.app&hl=de&gl=US">
                        <img v-if="this.$i18n.locale == 'de'" src="../assets/images/google_play_ger.png" alt="google play">
                    </a>
                </div>
            </div>
            <div class="col-lg-6 col-xs-12 col-sm-12 img-wrapper">
                <img class="hero-img" src="../assets/images/hero_background.png" alt="">
            </div>
        </div>
        <div class="second-section">
            <div class="container">
            <div class="row second-section-wrap">
                <div class="col-lg-6 col-xs-12 col-sm-12">
                    <div v-if="this.$i18n.locale == 'en'">
                         <video width="450" class="video"  controls :src="video_en" poster="../assets/images/image_explained.png"></video>
                    </div>
                    <div v-else>
                        <video  class="video" width="450" controls :src="video_de" poster="../assets/images/image_explained.png"></video>
                    </div>            
                </div>
                <div class="col-lg-6 col-xs-12 col-sm-12">
                    <h3 class="comb-explained">{{ $t('combMeExplained') }}</h3>
                    <p class="comb-explained-description">{{ $t('combMeExplainedDescription') }}</p>
                    <img v-if="this.$i18n.locale == 'en'" class="comb-description-img" src="../assets/images/sticker_eng.png" alt="">
                    <img v-if="this.$i18n.locale == 'de'" class="comb-description-img" src="../assets/images/sticker_ger.png" alt="">
                </div>
            </div>
            </div>
        </div>
        <div class="third-section" id="description">
            <div class="container">
            <div class="row">
              <h2 class="strenghts-headline">{{ $t('strenghtCombined') }}</h2>
               <div class="col-lg-6 col-xs-12 col-sm-12">
                    <h3 class="subheadline">{{ $t('otherToolLack') }}</h3>
                    <p class="uppercase-bold">{{ $t('strenghtCombined1') }}</p>
                    <p>{{ $t('strenghtCombined2') }}</p>
                    <p class="uppercase-bold">{{ $t('strenghtCombined3') }}</p>
                    <p>{{ $t('strenghtCombined4') }}</p>
                    <p class="uppercase-bold">{{ $t('strenghtCombined5') }}</p>
                    <p>{{ $t('strenghtCombined6') }}</p>
                    <p class="uppercase-bold">{{ $t('strenghtCombined7') }}</p>
                    <p>{{ $t('strenghtCombined8') }}</p>
                    <p class="uppercase-bold">{{ $t('strenghtCombined9') }}</p>
                    <p>{{ $t('strenghtCombined10') }}</p>
               </div>
                <div class="col-lg-6 col-xs-12 col-sm-12">
                    <h3 class="subheadline">{{ $t('combMe') }}</h3>
                    <p class="uppercase-bold">{{ $t('strenghtCombined11') }}</p>
                    <p>{{ $t('strenghtCombined12') }}</p>
                    <p class="uppercase-bold">{{ $t('strenghtCombined13') }}</p>
                    <p>{{ $t('strenghtCombined14') }}</p>
                    <p class="uppercase-bold">{{ $t('strenghtCombined15') }}</p>
                    <p>{{ $t('strenghtCombined16') }}</p>
                    <p class="uppercase-bold">{{ $t('strenghtCombined17') }}</p>
                    <p>{{ $t('strenghtCombined18') }}</p>
                    <p class="uppercase-bold">{{ $t('strenghtCombined19') }}</p>
                    <p>{{ $t('strenghtCombined20') }}</p>
               </div>
            </div>
            </div>
        </div>
         <div class="fourth-section" id="appoverview">
            <div class="container">
            <div class="row">
              <h3 class="looks-like-headline">{{ $t('combeMeLooksLike') }}</h3>
                <img v-if="this.$i18n.locale == 'en'" src="../assets/images/big_screens_english_new.png" class="big-screens" alt="">
                <img v-if="this.$i18n.locale == 'de'" src="../assets/images/big_screens_german_new.png" class="big-screens" alt="">
                <div id="carouselExampleIndicators" class="carousel slide small-screens" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img v-if="this.$i18n.locale == 'en'" class="d-block w-100" src="../assets/images/1_eng.png" alt="First slide">
                            <img v-if="this.$i18n.locale == 'de'" class="d-block w-100" src="../assets/images/1_ger.png" alt="First slide">
                        </div>
                        <div class="carousel-item">
                            <img v-if="this.$i18n.locale == 'en'" class="d-block w-100" src="../assets/images/2_eng.png" alt="Second slide">
                            <img v-if="this.$i18n.locale == 'de'" class="d-block w-100" src="../assets/images/2_ger.png" alt="Second slide">
                        </div>
                        <div class="carousel-item">
                            <img v-if="this.$i18n.locale == 'en'" class="d-block w-100" src="../assets/images/3_eng.png" alt="Third slide">
                            <img v-if="this.$i18n.locale == 'de'" class="d-block w-100" src="../assets/images/3_ger.png" alt="Third slide">
                        </div>
                        <div class="carousel-item">
                            <img v-if="this.$i18n.locale == 'en'" class="d-block w-100" src="../assets/images/4_eng.png" alt="Third slide">
                            <img v-if="this.$i18n.locale == 'de'" class="d-block w-100" src="../assets/images/4_ger.png" alt="Third slide">
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">{{ $t('previous') }}</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">{{ $t('next') }}</span>
                    </a>
                </div>
            </div>
            </div>
        </div>
        <div class="fifth-section" id="team">
            <div class="container">
            <div class="row">
              <h3 class="combme-thats-us">{{ $t('combeMeThatsUs') }}<span>{{ $t('thatsUs') }}</span></h3>
                <div class="col-lg-4 ">
                    <h2>Gordana Löwenkamp</h2>
                    <p>Co-Founder, CEO</p>
                    <p>{{ $t('gordanaDesc1') }}</p>
                </div>
                <div class="col-lg-4">
                    <img class="gordana-manuel-img" src="../assets/images/G_M.jpg" alt="">
                </div>
                <div class="col-lg-4">
                    <h2>Manuel Thiel</h2>
                    <p>Co-Founder, CTO</p>
                    <p>{{ $t('manuelDesc1') }}</p>
                </div>
            </div>
            </div>
        </div>
        <div class="sixth-section">
            <div class="container">
            <div class="row">
                <div class="col-lg-8">
                   <h3 class="story-comb">{{ $t('theStoryBehindComb') }}</h3>
                   <h5>{{ $t('theStoryBehindComb1') }}</h5>
                   <p>{{ $t('theStoryBehindComb2') }}</p>   
                    <div>
                    <p class="collapse" id="block-id-1">
                        {{ $t('theStoryBehindCombDescription1') }}
                    </p> 
                        <a href="#block-id-1" class="show-more-link" data-toggle="collapse" aria-expanded="false" aria-controls="block-id-1">
                        <span @click="showMore1" v-if="isShowMore1">
                            {{ $t('showMore') }}
                        </span>
                        <span @click="showLess1" v-else>
                            {{ $t('showLess') }}
                        </span>
                        </a>
                    </div>
                   <h5>{{ $t('theStoryBehindComb3') }}</h5>
                   <p>{{ $t('theStoryBehindComb4') }}</p>
                    <div>
                        <p class="collapse" id="block-id-2">
                            {{ $t('theStoryBehindCombDescription2') }}<br><br>
                            {{ $t('theStoryBehindCombDescription2a') }}<br>
                            {{ $t('theStoryBehindCombDescription2b') }}<br>
                            {{ $t('theStoryBehindCombDescription2c') }}<br>
                            {{ $t('theStoryBehindCombDescription2d') }}<br>
                            {{ $t('theStoryBehindCombDescription2e') }}<br>
                            {{ $t('theStoryBehindCombDescription2f') }}<br><br>
                            {{ $t('theStoryBehindCombDescription2g') }}
                        </p>  
                        <a href="#block-id-2" class="show-more-link" data-toggle="collapse" aria-expanded="false" aria-controls="block-id-2">
                        <span @click="showMore2" v-if="isShowMore2">
                            {{ $t('showMore') }}
                        </span>
                        <span @click="showLess2" v-else>
                            {{ $t('showLess') }}
                        </span>
                        </a>
                    </div>
                   <h5>{{ $t('theStoryBehindComb5') }}</h5>
                   <p>{{ $t('theStoryBehindComb6') }}</p>
                    <div>
                        <p class="collapse" id="block-id-3">
                            {{ $t('theStoryBehindCombDescription3') }}
                        </p>
                        <a href="#block-id-3" class="show-more-link" data-toggle="collapse" aria-expanded="false" aria-controls="block-id-3">
                        <span @click="showMore3" v-if="isShowMore3">
                            {{ $t('showMore') }}
                        </span>
                        <span @click="showLess3" v-else>
                            {{ $t('showLess') }}
                        </span>
                        </a>
                    </div>
                </div>
                <div class="col-lg-4">
                    <img class="logo-right" src="../assets/images/logo.png" alt="">
                </div>
            </div>
            </div>
        </div>
        <div class="form-section" id="contactus">
            <div class="container">
            <div class="row">
                <h3 class="form-headline">{{ $t('formHeadline') }}</h3>
                <p class="form-description">{{ $t('formDescription') }}</p>
                <div class="col-lg-3"></div>
                <div class="col-lg-6 col-xs-12 col-sm-12">
                    <div class="form-wrapper">
                        <div class="contact-form" ref="contactForm" action="#contactus">
                        <div class="form-group">
                            <label for="name"></label>
                            <input type="text"  
                                   class="form-control input-style"
                                   id="name" 
                                   :placeholder="$t('name')"
                                   v-model="name">
                            <p class="error-message" v-if="$v.name.$error">{{ $t('fieldIsRequired') }}</p>
                        </div>
                        <div class="form-group">
                            <label for="email"></label>
                            <input type="email" 
                                    class="form-control input-style" 
                                    id="email" 
                                    :placeholder="$t('email')"
                                    v-model="email">
                            <p class="error-message" v-if="$v.email.$email || $v.email.$error">{{ $t('fieldIsRequired') }}</p>
                        </div>
                        <div class="form-group">
                            <label for="message"></label>
                            <textarea class="form-control input-style" 
                                      id="message" rows="7" 
                                      :placeholder="$t('message')"
                                      v-model="message"></textarea>
                            <p class="error-message" v-if="$v.message.$error">{{ $t('fieldIsRequired') }}</p>
                        </div>
                        <button type="submit" @click="onSubmit" class="btn btn-submit">{{ $t('submit') }}</button>
                    </div>
                    </div>
                </div>
                <div class="col-lg-3"></div>
            </div>
            </div>
        </div>
        <combme-footer></combme-footer>
    </div>
  </div>
</template>

<script>
import Navigation from '../components/Navigation.vue'
import Footer from '../components/Footer.vue'
import { required, email } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import i18n from '../i18n';

export default {
        data () {
            return {
                name: '',
                email: '',
                message: '',
                repsMessage: '',
                isShowMore1: true,
                isShowMore2: true,
                isShowMore3: true,
                video_en: process.env.VUE_APP_VIDEO_EN,
                video_de: process.env.VUE_APP_VIDEO_DE
            }
        },
        validations:{
            name: {
                required
            },
            email:{
                required,
                email
            },
            message:{
                required
            }
        },
        computed: {
            ...mapGetters([
                'getResponseMessage'
            ])
        },
        methods: {
            onSubmit () {
                const formData = {
                    name: this.name,
                    email: this.email,
                    body: this.message
                }
                if (
                    this.$v.name.$invalid ||
                    this.$v.email.$invalid ||
                    this.$v.message.$invalid
                ) {
                        this.$v.$touch();
                        return;
                } else if (this.message.length >= 1000){
                      alert(i18n.t('max1000Char'));
                } else {
                    this.$store.dispatch('FORM_REQUEST', formData).then(() => {
                         this.clearForm();
                    });
                }
            },
            clearForm(){
                this.name = '',
                this.email = '', 
                this.message = ''
            },
            showMore1(){
                this.isShowLess1 = true;
                this.isShowMore1 = false;
            },
            showLess1(){
                this.isShowLess1 = false;
                this.isShowMore1 = true;
            },
            showMore2(){
                this.isShowLess2 = true;
                this.isShowMore2 = false;
            },
            showLess2(){
                this.isShowLess2 = false;
                this.isShowMore2 = true;
            },
            showMore3(){
                this.isShowLess3 = true;
                this.isShowMore3 = false;
            },
            showLess3(){
                this.isShowLess3 = false;
                this.isShowMore3 = true;
            },
        },
        components:{
            'combme-navigation': Navigation,
            'combme-footer': Footer
        }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .nav-wrapper{
        background: transparent linear-gradient(318deg, #606060 0%, #262626 100%) 0% 0% no-repeat padding-box;
        height: 85px;
    }
    .content-wrpper{
        color: #fff;
        font: 'Montserrat' !important;
    }

    .content-justify{
        padding-left: 50px;
        padding-right: 50px;
    }
    .background-grey{
        height: 105vh;
        background: transparent linear-gradient(318deg, #606060 0%, #262626 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        /* ? */
        margin-right: 0px !important;
    }
    .img-wrapper{
        position: relative;
    }
    .hero-img{
        position: absolute;
        top: 85px;
        right: 0px;
        opacity: 1;
        width: 100%;
    }
    .custom-container{
        padding-left: 200px; 
        position: relative;
        top: 150px;
        opacity: 1;
    }
    .first-line{
        /* position: relative; */
        /* top: 10px;
        left: 278px; */
        height: 120px;
        text-align: left;
        font: normal normal bold 80px/115px 'Montserrat';
        letter-spacing: -1.8px;
        color: #FFFFFF;
        opacity: 1;
    }
    .second-line{
        /* left: 278px;*/
        width: 665px;
        height: 60px; 
        font: 4rem/40px Montserrat;
        font-weight: 0;
        letter-spacing: -1.8px;
        font-weight: 100;

    }

    .third-line-div {
        display: flex;
        justify-content: flex-start;
    }
    .third-line{
        font: normal normal bold 90px/115px 'Montserrat';
        width: 411px;
        height: 110px;
        text-align: left;
        letter-spacing: -1.8px;
        color: #FFFFFF;
        opacity: 1;
    }
    .data-security-img {
        width: 25%;
        height: 20%;
        margin-left: 2.5rem;
    }
    .third-line> span{
         font: normal normal 100 80px/115px 'Montserrat';
    }
    .hero-grid{
        padding-top: 3vh;
        width: 120%;
    }
    .available-in-stores{
        padding-top: 20px;
    }

    .app-store{
        margin-right: 5px;
    }

    /* second section */
    .second-section{
        top: 1079px;
        left: 0px;
        height: 735px;
        background: #9F9F9F 0% 0% no-repeat padding-box;
        opacity: 1;
    }
    .second-section-wrap{
        padding-top: 120px;
    }
    .video{
        width: 95%;
        height: 450px;
        border-radius: 15px;
    }
    .comb-explained{
        width: 480px;
        height: 55px;
        letter-spacing: 0px;
        font-weight: 600;
    }
    .comb-explained-description{
        top: 1289px;
        left: 1093px;
        width: 553px;
        height: 112px;
        font-size: 16px;
    }
    /* 3 third section */
    .third-section{
        /* height: 735px;*/
        padding-bottom: 110px;
        background: transparent linear-gradient(318deg, #606060 0%, #262626 100%) 0% 0% no-repeat padding-box;
    }

    .strenghts-headline{
        font-size: 42px;
        text-align: center;
        padding-top: 11vh;
        padding-bottom: 5vh;
    }
    .uppercase-bold{
        text-transform: uppercase;
        font-weight: 700;
    }
    .subheadline{
        padding-bottom: 2vh;
    }

    /* 4 fourth section*/
    .fourth-section{
        /* height: 1064px; */
        height: 900px;
        background: #9F9F9F 0% 0% no-repeat padding-box;
    }
    .looks-like-headline{
        font-size: 42px;
        text-align: center;
        padding-top: 8vh;
        padding-bottom: 5vh;
    }

    .big-screens{
        display: block;
    }

    .small-screens{
        display: none;
    }

    /* 5 fifth section*/
    .fifth-section{
        height: 780px;
        background: transparent linear-gradient(318deg, #606060 0%, #262626 100%) 0% 0% no-repeat padding-box;
    }
    .combme-thats-us{
        text-align: center;
        font-size: 42px;
        padding-top: 10vh;
        padding-bottom: 8vh;
    }
    .gordana-manuel-img{
        border-radius: 5px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 85%;
    }

    /* 6  */
    .sixth-section{
        /* height: 780px; */
        height: auto;
        background: #393939 0% 0% no-repeat padding-box;
        padding-bottom: 8vh;
    }

    .story-comb{
        font-size: 42px;
        padding-top: 10vh;
        padding-bottom: 4vh;
    }
    .sixth-section  h5{
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .logo-right{
        position: relative;
        top: 40%;
        left: 20%;
        width: 231px;
        height: 307px;
    }
    /* 7 */
    .form-section{
        background-image: url("../assets/images/contact_us_background.png");
        height: 900px;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .form-headline{
        text-align: center;
        font-size: 42px;
        padding-top: 17vh;
        color: #393939;;
        padding-bottom: 1vh;
        font-weight: 600;
    }
    .form-description{
        text-align: center;
        font-size: 18px;
        color: #393939;
        padding-bottom: 1vh;
    }
    .contact-form{
        width: 632px;
    }
    .input-style{
        padding: 15px;
    }
    .btn-submit{
        float: right;
        margin-top: 15px;
        background-color: #606060;
        color: #fff;
        padding: 10px 20px;
        font-weight: 600;
    }
    textarea.form-control {
        max-height: 280px !important;
    }
    .error-message{
        color: rgb(255, 64, 64);
        margin-bottom: 0px;
        font-weight: 600;
    }

    .show-more-link{
        color: #32AADC;
        text-decoration: none;
        font-weight: 700;
    }
    .show-more-link:hover{
        color: #55c2f1;
    }

    @media only screen and (max-width: 768px) {
    .nav-wrapper{
        background: transparent linear-gradient(318deg, #606060 0%, #262626 100%) 0% 0% no-repeat padding-box;
        height: auto;
    }
    .content-justify{
        padding-left: 10px;
        padding-right: 50px;
    }
    /************** first section *************/
    .background-grey{
        height: 90vh;
        background: transparent linear-gradient(318deg, #606060 0%, #262626 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        margin-right: 0px !important;
    }
    .img-wrapper{
        /* position: relative; */
        display: none;
    }
   /* .hero-img{
         position: absolute;
        top: -650px;
        right: 0px;
        opacity: 1;
        width: 110%; 
    }
    */
    .custom-container{
        padding-left: 50px; 
        padding-right: 50px;
        position: relative; 
        /* top: 550px; */
        top: 15vh;
    }

    .header-line{
        width: 85%;
    }

    .first-line{
        /* position: relative; */
        /* top: 10px;
        left: 278px; */
        height: 80px;
        text-align: left;
        font: normal normal bold 40px/115px 'Montserrat';
        letter-spacing: -1.8px;
        color: #FFFFFF;
        opacity: 1;
    }
    .second-line{
        /* left: 278px;*/
        width: auto;
        height: 40px; 
        font: 28px Montserrat;
        font-weight: 0;
        letter-spacing: -1.8px;
        font-weight: 100;
        margin-bottom: 30px;
    }
    .third-line{
        font: normal normal bold 90px/115px 'Montserrat';
        width: auto;
        height: auto;
        text-align: left;
        letter-spacing: -1.8px;
        color: #FFFFFF;
        opacity: 1;
        font: 45px Montserrat;
    }
    .third-line> span{
         font: normal normal 100 50px/50px 'Montserrat';
    }
    .hero-grid{
        padding-top: 3vh;
        width: 100%;
    }
    .store-wrapper{
        width: 120%;
    }
    .store-wrapper  img{
        width: 42%;
    }
    .available-in-stores{
        padding-top: 20px;
    }

    /**************** *second section ********************/
    .second-section{
        top: 1079px;
        left: 0px;
        height: auto;
        background: #9F9F9F 0% 0% no-repeat padding-box;
        opacity: 1;
    }
    .second-section-wrap{
        padding-top: 10px;
    }
    .video{
        width: 100%;
        height: 400px;
    }
    .comb-explained{ 
        padding-top: 25px;
        margin-bottom: 10px !important;
        width: 100%;
        letter-spacing: 0px;
        font-weight: 600;
    }
    .comb-explained-description{
        width: auto;
        height: 112px;
        font-size: 16px;
    }
    .comb-description-img{
        width: 100%;
        top: 30px;
        position: relative;
    }

    /**************** *third section ********************/
    .strenghts-headline{
        text-align: left;
        font-size: 32px;
    }
    /**************** *fourth section ********************/
    .looks-like-headline{
        font-size: 32px;
    }

    .big-screens{
        display: none;
    }

    .small-screens{
        display: block;
    }

    /**************** *fifth section ********************/
    .fifth-section{
        padding-bottom: 4vh;
    }
    .combme-thats-us{
        padding-top: 6vh;
        padding-bottom: 4vh;
        text-align: left;
        font-size: 32px;
    }
    .fifth-section{
        height: auto;
    }
    .gordana-manuel-img{
        border-radius: 3px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    /**************** *sixth section ********************/
    .sixth-section{
        height: auto;
        padding-bottom: 4vh;
    }
    .story-comb{
        padding-top: 6vh;
        padding-bottom: 4vh;
        text-align: left;
        font-size: 32px;
    }
    .logo-right{
        position: relative;
        top: 5%;
        left: 35%;
        width: 100px;
        height: auto;
    }
    
    /**************** *seventh section ********************/
    .form-section{
        height: auto;
        padding-bottom: 4vh;
    }
    .form-headline{
        font-size: 32px;
        padding-top: 7vh;
    }
    .contact-form{
        width: auto;       
    }
 }
</style>