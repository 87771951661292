<template>
    <div id="top">
        <div class="nav-wrapper fixed-top">
            <nav class="navbar navbar-expand-sm fixed-top navbar-light bg-faded container">
                <img class="left-logo" src="../assets/images/logo.png" alt="COMBME">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingPolicyNavbar" aria-controls="#collapsingPolicyNavbar">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="collapsingPolicyNavbar">
                    <ul class="nav navbar-nav ml-auto w-100 justify-content-end">
                        <li class="nav-item" data-toggle="collapse" data-target="#collapsingPolicyNavbar">
                            <router-link :to="`/${$i18n.locale}/home`" class="nav-link active" tag="a">{{ $t('home') }}</router-link>
                        </li>
                        <li class="nav-item" data-toggle="collapse" data-target="#collapsingPolicyNavbar">
                            <img class="nav-link flag" src="../assets/images/german_flag.png" @click.prevent="setLocale('de')">
                        </li>
                        <li class="nav-item" data-toggle="collapse" data-target="#collapsingPolicyNavbar">
                            <img class="nav-link flag" src="../assets/images/english_flag.png" @click.prevent="setLocale('en')">
                        </li>    
                    </ul>
                </div>
            </nav>
        </div>
        <div class="privacy-content container">
          <div class="row privacy-text">
            <div class="col-lg-2">
              <img class="de-en-flag" src="../assets/images/privacy-en.png" alt="">
            </div>
            <div class="panel-group col-lg-10" id="accordionSingleClosed" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default ">
                    <div class="panel-heading" role="tab" id="headingOne">
                    <h4 class="panel-title">
                        <div class="collapsed" role="button" data-toggle="collapse" href="#collapseItemCloseOne" aria-expanded="true" aria-controls="collapseItemCloseOne">
                            <h3>ENGLISH / ENGLISCH</h3>
                            <h4>Privacy statement</h4>
                        </div>
                    </h4>
                    </div>
                    <div id="collapseItemCloseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                    <div class="panel-body">
                1) Information on the collection of personal data and contact details of the responsible person
                1.1 We are pleased that you are visiting our website and thank you for your interest. In the following we inform you about the handling of your personal data when using our website. Personal data is all data with which you can be personally identified.
                1.2 The person responsible for data processing on this website within the meaning of the Data Protection Basic Regulation (DSGVO) is CombMe UG (limited liability), Backesweg 54A, 63477 Maintal, Germany, E-Mail: info@combme.com. The controller of personal data shall be the natural or legal person who alone or jointly with others determines the purposes and means of the processing of personal data.
                1.3 This website uses SSL or TLS encryption for security reasons and to protect the transmission of personal data and other confidential content (e.g. orders or enquiries to the responsible person). You can recognize an encrypted connection by the character string "https://" and the lock symbol in your browser line.

                2) Data collection when visiting our website
                If you only use our website for information purposes, i.e. if you do not register or otherwise provide us with information, we only collect data that your browser transmits to our server (so-called "server log files"). When you call up our website, we collect the following data, which is technically necessary for us to display the website to you:
                - Our visited website
                - Date and time at the time of access
                - Amount of data sent in bytes
                - Source/reference from which you came to the page
                - Browser used
                - Operating system used
                - IP address used (if applicable: in anonymous form)
                Processing is carried out in accordance with Art. 6 Para. 1 lit. f DSGVO on the basis of our legitimate interest in improving the stability and functionality of our website. The data will not be passed on or used in any other way. However, we reserve the right to subsequently check the server log files if there are concrete indications of illegal use.

                3) Cookies
                In order to make visiting our website attractive and to enable the use of certain functions, we use so-called cookies on various pages. These are small text files that are stored on your mobile device. Some of the cookies we use are deleted after the end of the browser session, i.e. after closing your browser (so-called session cookies). Other cookies remain on your terminal and enable us or our partner companies (third-party cookies) to recognize your browser the next time you visit (persistent cookies). If cookies are set, they collect and process certain user information such as browser and location data as well as IP address values to an individual extent. Persistent cookies are automatically deleted after a specified period, which may vary depending on the cookie.
                If personal data are also processed by individual cookies implemented by us, the processing takes place in accordance with Art. 6 Para. 1 lit. f DSGVO to safeguard our legitimate interests in the best possible functionality of the website and a customer-friendly and effective design of the page visit.
                We may work with advertising partners to help us make our website more interesting to you. For this purpose, cookies from partner companies will also be stored on your hard drive (cookies from third parties) when you visit our website. If we cooperate with the aforementioned advertising partners, you will be informed individually and separately about the use of such cookies and the scope of the information collected in each case within the following paragraphs.
                Please note that you can set your browser so that you are informed about the setting of cookies and can decide individually whether to accept them or whether to exclude the acceptance of cookies in certain cases or in general. Each browser differs in the way it manages cookie settings. This is described in the help menu of each browser, which explains how you can change your cookie settings. These can be found for the respective browsers under the following links:
                Internet Explorer: https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
                Firefox: https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
                Chrome: https://support.google.com/chrome/answer/95647?hl=en&amp;hlrm=en
                Safari: https://support.apple.com/de-de/guide/safari/sfri11471/mac
                Opera: https://help.opera.com/en/latest/web-preferences/#cookies
                Please note that if cookies are not accepted, the functionality of our website may be restricted.

                4) Making contact
                Within the scope of contacting us (e.g. via contact form or e-mail), personal data is collected. Which data is collected in the case of a contact form, is apparent from the respective contact form. These data are stored and used exclusively for the purpose of answering your request or for establishing contact and the associated technical administration. The legal basis for the processing of the data is our legitimate interest in responding to your request pursuant to Art. 6 Para. 1 lit. f DSGVO. If your contact is aimed at the conclusion of a contract, the additional legal basis for the processing is Art. 6 para. 1 lit. b DSGVO. Your data will be deleted after the final processing of your request, this is the case if the circumstances indicate that the matter in question has been conclusively clarified and provided there are no legal obligations to retain data.

                5) Use of your data for direct marketing purposes
                5.1 Registration for our e-mail newsletter
                If you subscribe to our e-mail newsletter, we will regularly send you information about our offers. Your e-mail address is the only mandatory information for sending the newsletter. The provision of any further data is voluntary and will be used to address you personally. We use the so-called double opt-in procedure to send the newsletter. This means that we will only send you an e-mail newsletter after you have expressly confirmed that you agree to receive the newsletter. We will then send you a confirmation e-mail asking you to click on a link to confirm that you wish to receive our newsletter in the future.
                By activating the confirmation link, you consent to the use of your personal data in accordance with Art. 6 para. 1 lit. a DSGVO. When you register for the newsletter, we store your IP address entered by the Internet Service Provider (ISP) as well as the date and time of registration in order to be able to track any possible misuse of your e-mail address at a later point in time. The data collected by us when registering for the newsletter will be used exclusively for the purposes of advertising by way of the newsletter. You can unsubscribe from the newsletter at any time via the link provided for this purpose in the newsletter or by sending a corresponding message to the responsible person named at the beginning. After you have unsubscribed, your e-mail address will be immediately deleted from our newsletter distribution list unless you have expressly consented to the further use of your data or unless we reserve the right to make further use of your data, which is permitted by law and about which we inform you in this declaration.
                5.2 Newsletter dispatch via Newsletter2Go
                Our e-mail newsletter is sent via the technical service provider Newsletter2Go GmbH, Köpenicker Str. 126, 10179 Berlin ("Newsletter2GO"), to whom we pass on the data you provide when you register for the newsletter. This disclosure is made in accordance with Art. 6 Para. 1 lit. f DSGVO and serves our legitimate interest in the use of an effective, secure and user-friendly newsletter system. The data entered by you for newsletter subscription (e.g. e-mail address) will be stored on the servers of Newsletter2GO in Germany.
                Newsletter2GO uses this information for the dispatch and statistical evaluation of the newsletter on our behalf. For evaluation purposes, the e-mails sent contain so-called web beacons or tracking pixels, which represent one-pixel image files stored on our website. In this way it can be determined whether a newsletter message has been opened and which links have been clicked. Conversion tracking can also be used to analyse whether a predefined action (e.g. purchase of a product on our website) has taken place after clicking the link in the newsletter. Technical information is also recorded (e.g. time of access, IP address, browser type and operating system). The data will be collected exclusively pseudonymized and will not be linked to your other personal data, a direct personal reference is excluded. This data is used exclusively for statistical analysis of newsletter campaigns. The results of these analyses can be used to better tailor future newsletters to the interests of recipients.
                If you wish to object to the data analysis for statistical evaluation purposes, you must unsubscribe from the newsletter.
                We have concluded an order processing contract with Newsletter2GO in which we oblige Newsletter2GO to protect the data of our customers and not to pass it on to third parties.
                For more information about Newsletter2GO's privacy policy, please refer to Newsletter2Go's privacy policy: https://www.newsletter2go.at/datenschutz/

                6) Use of Social Media: Videos
                Use of Youtube videos
                This website uses the Youtube embedding feature to display and play videos from "Youtube", a provider owned by Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland ("Google").
                The extended data protection mode is used here, which, according to the provider, only initiates the storage of user information when the video(s) is/are played. If the playback of embedded Youtube videos is started, the provider "Youtube" uses cookies to collect information about the user behaviour. According to information from Youtube, these serve, among other things, to collect video statistics, improve user-friendliness and prevent abusive practices. If you're logged in to Google, your information will be directly associated with your account when you click a video. If you do not want your profile to be associated with YouTube, you must log out before activating the button. Google stores your data (even for users who are not logged in) as usage profiles and evaluates them. In particular, such evaluation shall be carried out in accordance with Art. 6 para. 1 lit.f DSGVO on the basis of Google's legitimate interests in the display of personalised advertising, market research and/or the demand-oriented design of its website. You have the right to object to the creation of these user profiles, and you must contact YouTube to exercise this right. When using Youtube, personal data may also be transmitted to the servers of Google LLC. in the USA.
                Regardless of how the embedded video is played, each time you visit this site, you will be connected to the Google network, which may trigger further data processing without our control.
                In the event that personal data is transferred to Google LLC. based in the USA, Google LLC. has certified itself for the us European data protection agreement "Privacy Shield", which guarantees compliance with the data protection level applicable in the EU. A current certificate can be viewed here: https://www.privacyshield.gov/list
                Further information on data protection at "YouTube" can be found in the provider's data protection declaration at: https://www.google.de/intl/de/policies/privacy

                7) Web analytics services
                Google (Universal) Analytics
                Google Analytics with demographic characteristics
                This website uses Google Analytics, a web analysis service provided by Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland ("Google"). Google Analytics uses "cookies", which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of this website (including your abbreviated IP address) will generally be transmitted to and stored by Google on servers in the United States, where it may also be transmitted to servers of Google LLC.
                This website uses Google Analytics exclusively with the extension "_anonymizeIp()", which ensures an anonymization of the IP address by shortening and excludes a direct personal reference. By the extension your IP address will be shortened by Google within member states of the European Union or in other contracting states of the Agreement on the European Economic Area before. Only in exceptional cases is the full IP address transmitted to a server of Google LLC.in the USA and shortened there. In these exceptional cases, this processing takes place in accordance with Art. 6 Para. 1 lit. f DSGVO on the basis of our legitimate interest in the statistical analysis of user behaviour for optimisation and marketing purposes.
                On our behalf, Google will use this information to evaluate your use of the website, to compile reports on website activity and to provide us with other services relating to website activity and Internet usage. The IP address transmitted by your browser as part of Google Analytics is not combined with other data from Google.
                You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. You can also prevent Google from collecting the data generated by the cookie and related to your use of the website (including your IP address) as well as Google from processing this data by downloading and installing the browser plug-in available under the following link:
                &amp;lt;font color=#38B0DE&amp;gt;-=https://tools.google.com/dlpage/gaoptout?hl=- Proudly Presents
                As an alternative to the browser plug-in or within browsers on mobile devices, please click the following link to set an opt-out cookie that will prevent Google Analytics from collecting cookies from this site in the future (this opt-out cookie only works in this browser and only for this domain, delete your cookies in this browser, you must click this link again): &lt;a onclick="alert('Google Analytics was disabled');" href="javascript:gaOptout()"&gt;Disable Google Analytics&lt;/a&gt;
                In the event that personal data is transferred to Google LLC. based in the USA, Google LLC. has certified itself for the us European data protection agreement "Privacy Shield", which guarantees compliance with the data protection level applicable in the EU. A current certificate can be viewed here: https://www.privacyshield.gov/list
                This website also uses Google Analytics' "demographic features" feature. This allows reports to be generated that contain statements on demographic data such as age, gender, and interests of site visitors. This information comes from interest-based advertising by Google, the Google Display Network, and visitor information from third parties. You can deactivate this function at any time via the ad settings in your Google Account or generally prohibit the collection of your data by Google Analytics as shown immediately.
                You can deactivate it using a Google browser plugin (https://tools.google.com/dlpage/gaoptout?hl=en). As an alternative to the browser plug-in or within browsers on mobile devices, please click the following link to set an opt-out cookie that will prevent Google Analytics from collecting cookies from this site in the future (this opt-out cookie only works in this browser and only for this domain, delete your cookies in this browser, you must click this link again): &lt;a onclick="alert('Google Analytics was disabled');" href="javascript:gaOptout()"&gt;Disable Google Analytics&lt;/a&gt;
                Further information about Google (Universal) Analytics can be found here: https://support.google.com/analytics/answer/2838718?hl=en&amp;ref_topic=6010376

                8) Tools and Miscellaneous
                8.1 Google Maps
                On our website we use Google Maps (API) from Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland ("Google"). Google Maps is a web service for displaying interactive (land) maps to visually display geographic information. Using this service will show you our location and make it easier for you to reach us.
                Already when calling up the subpages in which the Google Maps map is integrated, information about your use of our website (e.g. your IP address) is transmitted to and stored by Google on servers of Google LLC. in the USA. This occurs regardless of whether Google provides a user account that you are logged in to or whether there is no user account. When you're logged in to Google, your information will be directly associated with your account. If you do not want your profile to be associated with Google, you must log out before activating the button. Google stores your data (even for users who are not logged in) as usage profiles and evaluates them. The collection, storage and evaluation are carried out in accordance with Art. 6 para. 1 lit.f DSGVO on the basis of Google's legitimate interests in the display of personalised advertising, market research and/or the demand-oriented design of Google websites. You have the right to object to the creation of these user profiles, and you must contact Google to exercise this right.
                In the event that personal data is transferred to Google LLC. based in the USA, Google LLC. has certified itself for the us European data protection agreement "Privacy Shield", which guarantees compliance with the data protection level applicable in the EU. A current certificate can be viewed here: https://www.privacyshield.gov/list
                If you do not agree with the future transmission of your data to Google within the scope of the use of Google Maps, there is also the possibility to completely deactivate the web service of Google Maps by switching off the application JavaScript in your browser. Google Maps and thus also the map display on this website can then not be used.
                The Google Terms of Use can be found at https://www.google.de/intl/de/policies/terms/regional.html, the additional Google Maps Terms of Use can be found at https://www.google.com/intl/de_US/help/terms_maps.html
                Detailed information on data protection in connection with the use of Google Maps can be found on Google's website ("Google Privacy Policy"): https://www.google.de/intl/de/policies/privacy/
                8.2 Google Web Fonts
                This site uses web fonts provided by Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland ("Google") for the purpose of displaying fonts in a consistent manner. When you call up a page, your browser loads the required web fonts into its browser cache in order to display texts and fonts correctly.
                For this purpose, the browser you use must connect to Google's servers, which may also involve the transmission of personal data to Google LLC's servers in the United States. This gives Google knowledge that our website has been accessed via your IP address. The use of Google Web Fonts is in the interest of a uniform and appealing presentation of our online offerings. This constitutes a legitimate interest within the meaning of Art. 6 para. 1 lit. f DSGVO. If your browser does not support web fonts, a standard font will be used by your computer.
                In the event that personal data is transferred to Google LLC. based in the USA, Google LLC. has certified itself for the us European data protection agreement "Privacy Shield", which guarantees compliance with the data protection level applicable in the EU. A current certificate can be viewed here: https://www.privacyshield.gov/list
                More information about Google Web Fonts can be found at https://developers.google.com/fonts/faq and in Google's privacy policy: https://www.google.com/policies/privacy/

                9) Rights of the data subject
                9.1 The applicable data protection law grants you comprehensive rights (information and intervention rights) vis-à-vis the person responsible with regard to the processing of your personal data, about which we will inform you below:
                - Right of access pursuant to Art. 15 DSGVO: In particular, you have a right of access to your personal data processed by us, the processing purposes, the categories of personal data processed, the recipients or categories of recipients to whom your data have been or will be disclosed, the planned storage period or the planned storage period. the criteria for determining the storage period, the existence of a right to rectification, deletion, restriction of processing, objection to processing, complaint to a supervisory authority, the origin of your data if it has not been collected by us from you, the existence of automated decision-making including profiling and, where applicable, meaningful information on the logic involved and the scope and desired effects of such processing concerning you, as well as your right to be informed of the guarantees pursuant to Art. 46 DSGVO in the event of transfer of your data to third countries;
                - Right of rectification according to art. 16 DSGVO: You have the right of immediate rectification of incorrect data concerning you and/or completion of incomplete data stored by us;
                - Right to deletion pursuant to Art. 17 DSGVO: You have the right to demand the deletion of your personal data if the requirements of Art. 17 para. 1 DSGVO are met. However, this right shall not apply in particular if the processing is necessary for the exercise of the right to freedom of expression and information, for the fulfilment of a legal obligation, for reasons of public interest or for the assertion, exercise or defence of legal claims;
                - Right to limitation of processing pursuant to Art. 18 DSGVO: You have the right to demand limitation of processing of your personal data as long as the correctness of your data is verified, which you dispute, if you refuse to delete your data due to inadmissible data processing and instead demand limitation of processing of your data, if you need your data to assert, exercise or defend legal claims, after we no longer need this data after achieving the purpose, or if you have lodged an objection due to your particular situation, as long as it is not yet clear whether our legitimate reasons prevail;
                - Right to information pursuant to Art. 19 DSGVO: If you have exercised your right to rectify, cancel or limit the processing of your personal data against the controller, the latter is obliged to notify all recipients to whom the personal data concerning you have been disclosed of such rectification, cancellation or limitation, unless this proves impossible or involves a disproportionate effort. They shall have the right to be informed of such recipients.
                - Right to data transfer in accordance with Art. 20 DSGVO: You have the right to receive your personal data which you have provided to us in a structured, common and machine-readable format or to request the transfer to another responsible person as far as this is technically feasible;
                - Right to revoke consent granted pursuant to Art. 7 (3) DSGVO: You have the right to revoke consent once granted for the processing of data at any time with effect for the future. In the event of revocation, we will immediately delete the data concerned, unless further processing can be based on a legal basis for processing without consent. The revocation of the consent does not affect the lawfulness of the processing carried out on the basis of the consent up to the revocation;
                - Right to appeal pursuant to Art. 77 DSGVO: If you believe that the processing of your personal data is in breach of the DSGVO, you have the right to complain to a supervisory authority, in particular in the Member State in which you are located, at your place of work or at the place where the alleged infringement is alleged, without prejudice to any other administrative or judicial remedy.
                9.2 RIGHT OF OBJECTION
                IF WE PROCESS YOUR PERSONAL DATA AS PART OF A WEIGHING OF INTERESTS ON THE BASIS OF OUR PREDOMINANTLY LEGITIMATE INTEREST, YOU HAVE THE RIGHT AT ANY TIME TO OBJECT TO SUCH PROCESSING WITH EFFECT FOR THE FUTURE FOR REASONS ARISING FROM YOUR PARTICULAR SITUATION.
                IF YOU MAKE USE OF YOUR RIGHT OF OBJECTION, WE WILL STOP PROCESSING THE DATA CONCERNED. HOWEVER, WE RESERVE THE RIGHT TO FURTHER PROCESSING IF WE CAN PROVE COMPELLING GROUNDS FOR PROCESSING WORTHY OF PROTECTION WHICH OUTWEIGH THEIR INTERESTS, FUNDAMENTAL RIGHTS AND FREEDOMS, OR IF THE PROCESSING SERVES THE ASSERTION, EXERCISE OR DEFENCE OF LEGAL CLAIMS.
                IF YOUR PERSONAL DATA IS PROCESSED BY US IN ORDER TO CONDUCT DIRECT ADVERTISING, YOU HAVE THE RIGHT TO OBJECT AT ANY TIME TO THE PROCESSING OF YOUR PERSONAL DATA FOR THE PURPOSE OF SUCH ADVERTISING. YOU CAN EXERCISE THE OBJECTION AS DESCRIBED ABOVE.
                IF YOU MAKE USE OF YOUR RIGHT OF OBJECTION, WE WILL STOP PROCESSING THE DATA CONCERNED FOR DIRECT ADVERTISING PURPOSES.

                10) Duration of storage of personal data
                The duration of the storage of personal data is determined by the respective legal retention period (e.g. commercial and tax retention periods). After expiry of this period, the corresponding data will be routinely deleted if they are no longer required for contract fulfilment or contract initiation and/or if we do not continue to have a justified interest in further storage.
                </div>
                </div>
            </div>
            </div>
          </div>
          <div class="row privacy-text">
            <div class="col-lg-2">
              <img class="de-en-flag" src="../assets/images/german-flag-privacy.png" alt="">
            </div>
            <div class="panel-group col-lg-10" id="accordionSingleClosed" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default ">
                    <div class="panel-heading" role="tab" id="headingTwo">
                    <h4 class="panel-title">
                        <div class="collapsed" role="button" data-toggle="collapse" href="#collapseItemCloseTwo" aria-expanded="true" aria-controls="collapseItemCloseTwo">
                            <h3>GERMAN / DEUTSCH</h3>
                            <h4>Datenschutzerklärung</h4>
                        </div>
                    </h4>
                    </div>
                    <div id="collapseItemCloseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                    <div class="panel-body">
                       1) Information über die Erhebung personenbezogener Daten und Kontaktdaten des Verantwortlichen

                  1.1 Wir freuen uns, dass Sie unsere Website besuchen und bedanken uns für Ihr Interesse. Im Folgenden informieren wir Sie über den Umgang mit Ihren personenbezogenen Daten bei Nutzung unserer Website. Personenbezogene Daten sind hierbei alle Daten, mit denen Sie persönlich identifiziert werden können.

                  1.2 Verantwortlicher für die Datenverarbeitung auf dieser Website im Sinne der Datenschutz-Grundverordnung (DSGVO) ist die CombMe UG (haftungsbeschränkt), Backesweg 54 A, 63477 Maintal, Deutschland, E-Mail: info@combme.com . Der für die Verarbeitung von personenbezogenen Daten Verantwortliche ist diejenige natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.

                  1.3 Diese Website nutzt aus Sicherheitsgründen und zum Schutz der Übertragung personenbezogener Daten und anderer vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an den Verantwortlichen) eine SSL-bzw. TLS-Verschlüsselung. Sie können eine verschlüsselte Verbindung an der Zeichenfolge „https://“ und dem Schloss-Symbol in Ihrer Browserzeile erkennen.

                  &nbsp;

                  2) Datenerfassung beim Besuch unserer Website

                  Bei der bloß informatorischen Nutzung unserer Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen übermitteln, erheben wir nur solche Daten, die Ihr Browser an unseren Server übermittelt (sog. „Server-Logfiles“). Wenn Sie unsere Website aufrufen, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen die Website anzuzeigen:

                  - Unsere besuchte Website

                  - Datum und Uhrzeit zum Zeitpunkt des Zugriffes

                  - Menge der gesendeten Daten in Byte

                  - Quelle/Verweis, von welchem Sie auf die Seite gelangten

                  - Verwendeter Browser

                  - Verwendetes Betriebssystem

                  - Verwendete IP-Adresse (ggf.: in anonymisierter Form)

                  Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilität und Funktionalität unserer Website. Eine Weitergabe oder anderweitige Verwendung der Daten findet nicht statt. Wir behalten uns allerdings vor, die Server-Logfiles nachträglich zu überprüfen, sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.

                  &nbsp;

                  3) Cookies

                  Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir auf verschiedenen Seiten sogenannte Cookies. Hierbei handelt es sich um kleine Textdateien, die auf Ihrem Endgerät abgelegt werden. Einige der von uns verwendeten Cookies werden nach dem Ende der Browser-Sitzung, also nach Schließen Ihres Browsers, wieder gelöscht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns oder unseren Partnerunternehmen (Cookies von Drittanbietern), Ihren Browser beim nächsten Besuch wiederzuerkennen (persistente Cookies). Werden Cookies gesetzt, erheben und verarbeiten diese im individuellen Umfang bestimmte Nutzerinformationen wie Browser- und Standortdaten sowie IP-Adresswerte. Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann.

                  Sofern durch einzelne von uns implementierte Cookies auch personenbezogene Daten verarbeitet werden, erfolgt die Verarbeitung gemäß Art. 6 Abs. 1 lit. f DSGVO zur Wahrung unserer berechtigten Interessen an der bestmöglichen Funktionalität der Website sowie einer kundenfreundlichen und effektiven Ausgestaltung des Seitenbesuchs.

                  Wir arbeiten unter Umständen mit Werbepartnern zusammen, die uns helfen, unser Internetangebot für Sie interessanter zu gestalten. Zu diesem Zweck werden für diesen Fall bei Ihrem Besuch unserer Website auch Cookies von Partnerunternehmen auf Ihrer Festplatte gespeichert (Cookies von Drittanbietern). Wenn wir mit vorbenannten Werbepartnern zusammenarbeiten, werden Sie über den Einsatz derartiger Cookies und den Umfang der jeweils erhobenen Informationen innerhalb der nachstehenden Absätze individuell und gesondert informiert.

                  Bitte beachten Sie, dass Sie Ihren Browser so einstellen können, dass Sie über das Setzen von Cookies informiert werden und einzeln über deren Annahme entscheiden oder die Annahme von Cookies für bestimmte Fälle oder generell ausschließen können. Jeder Browser unterscheidet sich in der Art, wie er die Cookie-Einstellungen verwaltet. Diese ist in dem Hilfemenü jedes Browsers beschrieben, welches Ihnen erläutert, wie Sie Ihre Cookie-Einstellungen ändern können. Diese finden Sie für die jeweiligen Browser unter den folgenden Links:

                  Internet Explorer: https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies

                  Firefox: https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen

                  Chrome: https://support.google.com/chrome/answer/95647?hl=de&amp;hlrm=en

                  Safari: https://support.apple.com/de-de/guide/safari/sfri11471/mac

                  Opera: https://help.opera.com/en/latest/web-preferences/#cookies

                  Bitte beachten Sie, dass bei Nichtannahme von Cookies die Funktionalität unserer Website eingeschränkt sein kann.

                  &nbsp;

                  4) Kontaktaufnahme

                  Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle eines Kontaktformulars erhoben werden, ist aus dem jeweiligen Kontaktformular ersichtlich. Diese Daten werden ausschließlich zum Zweck der Beantwortung Ihres Anliegens bzw. für die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Rechtsgrundlage für die Verarbeitung der Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden nach abschließender Bearbeitung Ihrer Anfrage gelöscht, dies ist der Fall, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.

                  &nbsp;

                  5) Nutzung Ihrer Daten zur Direktwerbung

                  5.1 Anmeldung zu unserem E-Mail-Newsletter

                  Wenn Sie sich zu unserem E-Mail Newsletter anmelden, übersenden wir Ihnen regelmäßig Informationen zu unseren Angeboten. Pflichtangabe für die Übersendung des Newsletters ist allein Ihre E-Mailadresse. Die Angabe weiterer evtl. Daten ist freiwillig und wird verwendet, um Sie persönlich ansprechen zu können. Für den Versand des Newsletters verwenden wir das sog. Double Opt-in Verfahren. Dies bedeutet, dass wir Ihnen erst dann einen E-Mail Newsletter übermitteln werden, wenn Sie uns ausdrücklich bestätigt haben, dass Sie in den Versand von Newsletter einwilligen. Wir schicken Ihnen dann eine Bestätigungs-E-Mail, mit der Sie gebeten werden durch Anklicken eines entsprechenden Links zu bestätigen, dass Sie künftig Newsletter erhalten wollen.

                  Mit der Aktivierung des Bestätigungslinks erteilen Sie uns Ihre Einwilligung für die Nutzung Ihrer personenbezogenen Daten gemäß Art. 6 Abs. 1 lit. a DSGVO. Bei der Anmeldung zum Newsletter speichern wir Ihre vom Internet Service-Provider (ISP) eingetragene IP-Adresse sowie das Datum und die Uhrzeit der Anmeldung, um einen möglichen Missbrauch Ihrer E-Mail- Adresse zu einem späteren Zeitpunkt nachvollziehen zu können. Die von uns bei der Anmeldung zum Newsletter erhobenen Daten werden ausschließlich für Zwecke der werblichen Ansprache im Wege des Newsletters benutzt. Sie können den Newsletter jederzeit über den dafür vorgesehenen Link im Newsletter oder durch entsprechende Nachricht an den eingangs genannten Verantwortlichen abbestellen. Nach erfolgter Abmeldung wird Ihre E-Mailadresse unverzüglich in unserem Newsletter-Verteiler gelöscht, soweit Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüberhinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren.

                  5.2 Newsletterversand via Newsletter2Go

                  Der Versand unserer E-Mail-Newsletter erfolgt über den technischen Dienstleister Newsletter2Go GmbH, Köpenicker Str. 126 , 10179 Berlin („Newsletter2GO“), an die wir Ihre bei der Newsletteranmeldung bereitgestellten Daten weitergeben. Diese Weitergabe erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO und dient unserem berechtigten Interesse an der Verwendung eines werbewirksamen, sicheren und nutzerfreundlichen Newslettersystems. Die von Ihnen zwecks Newsletterbezugs eingegebenen Daten (z.B. E-Mail-Adresse) werden auf den Servern von Newsletter2GO in Deutschland gespeichert.

                  Newsletter2GO verwendet diese Informationen zum Versand und zur statistischen Auswertung der Newsletter in unserem Auftrag. Für die Auswertung beinhalten die versendeten E-Mails sog. Web-Beacons bzw. Trackings-Pixel, die Ein-Pixel-Bilddateien darstellen, die auf unserer Website gespeichert sind. So kann festgestellt werden, ob eine Newsletter-Nachricht geöffnet und welche Links ggf. angeklickt wurden. Mit Hilfe des sog. Conversion-Trackings kann außerdem analysiert werden, ob nach Anklicken des Links im Newsletter eine vorab definierte Aktion (z.B. Kauf eines Produkts auf unserer Website) erfolgt ist. Außerdem werden technische Informationen erfasst (z.B. Zeitpunkt des Abrufs, IP-Adresse, Browsertyp und Betriebssystem). Die Daten werden ausschließlich pseudonymisiert erhoben und werden nicht mir Ihren weiteren persönlichen Daten verknüpft, eine direkte Personenbeziehbarkeit wird ausgeschlossen. Diese Daten dienen ausschließlich der statistischen Analyse von Newsletterkampagnen. Die Ergebnisse dieser Analysen können genutzt werden, um künftige Newsletter besser an die Interessen der Empfänger anzupassen.

                  Wenn Sie der Datenanalyse zu statistischen Auswertungszwecken widersprechen möchten, müssen Sie den Newsletterbezug abbestellen.

                  Wir haben mit Newsletter2GO einen Auftragsverarbeitungsvertrag abgeschlossen, in welchem wir die Newsletter2GO verpflichten, die Daten unserer Kunden zu schützen und sie nicht an Dritte weiterzugeben.

                  Weitere Informationen zum Datenschutz von Newsletter2GO können Sie in der Datenschutzerklärung von Newsletter2Go nachlesen: https://www.newsletter2go.at/datenschutz/

                  &nbsp;

                  6) Verwendung von Sozialen Medien: Videos

                  Verwendung von Youtube-Videos

                  Diese Website nutzt die Youtube-Einbettungsfunktion zur Anzeige und Wiedergabe von Videos des Anbieters „Youtube“, der zu der Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland („Google“) gehört.

                  Hierbei wird der erweiterte Datenschutzmodus verwendet, der nach Anbieterangaben eine Speicherung von Nutzerinformationen erst bei Wiedergabe des/der Videos in Gang setzt. Wird die Wiedergabe eingebetteter Youtube-Videos gestartet, setzt der Anbieter „Youtube“ Cookies ein, um Informationen über das Nutzerverhalten zu sammeln. Hinweisen von „Youtube“ zufolge dienen diese unter anderem dazu, Videostatistiken zu erfassen, die Nutzerfreundlichkeit zu verbessern und missbräuchliche Handlungsweisen zu unterbinden. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet, wenn Sie ein Video anklicken. Wenn Sie die Zuordnung mit Ihrem Profil bei YouTube nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. Google speichert Ihre Daten (selbst für nicht eingeloggte Nutzer) als Nutzungsprofile und wertet diese aus. Eine solche Auswertung erfolgt insbesondere gemäß Art. 6 Abs. 1 lit.f DSGVO auf Basis der berechtigten Interessen von Google an der Einblendung personalisierter Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an YouTube richten müssen. Im Rahmen der Nutzung von Youtube kann es auch zu einer Übermittlung von personenbezogenen Daten an die Server der Google LLC. in den USA kommen.

                  Unabhängig von einer Wiedergabe der eingebetteten Videos wird bei jedem Aufruf dieser Website eine Verbindung zum Google-Netzwerk aufgenommen, was ohne unseren Einfluss weitere Datenverarbeitungsvorgänge auslösen kann.

                  Für den Fall der Übermittlung von personenbezogenen Daten an die Google LLC. mit Sitz in den USA, hat sich Google LLC. für das us-europäische Datenschutzübereinkommen „Privacy Shield“ zertifiziert, welches die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet. Ein aktuelles Zertifikat kann hier eingesehen werden: https://www.privacyshield.gov/list

                  Weitere Informationen zum Datenschutz bei „YouTube“ finden Sie in der Datenschutzerklärung des Anbieters unter: https://www.google.de/intl/de/policies/privacy

                  &nbsp;

                  7) Webanalysedienste

                  Google (Universal) Analytics

                  Google Analytics mit demografischen Merkmalen

                  Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland ("Google"). Google Analytics verwendet sog. "Cookies", Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website (einschließlich der gekürzten IP-Adresse) werden in der Regel an einen Server von Google übertragen und dort gespeichert, hierbei kann es auch zu einer Übermittlung an die Server der Google LLC. in den USA kommen.

                  Diese Website verwendet Google Analytics ausschließlich mit der Erweiterung "_anonymizeIp()", die eine Anonymisierung der IP-Adresse durch Kürzung sicherstellt und eine direkte Personenbeziehbarkeit ausschließt. Durch die Erweiterung wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google LLC.in den USA übertragen und dort gekürzt. In diesen Ausnahmefällen erfolgt diese Verarbeitung gemäß Art. 6 Abs. 1 lit. f DSGVO auf Grundlage unseres berechtigten Interesses an der statistischen Analyse des Nutzerverhaltens zu Optimierungs- und Marketingzwecken.

                  In unserem Auftrag wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen uns gegenüber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.

                  Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:

                  https://tools.google.com/dlpage/gaoptout?hl=de

                  Alternativ zum Browser-Plugin oder innerhalb von Browsern auf mobilen Geräten klicken Sie bitte auf den folgenden Link, um ein Opt-Out-Cookie zu setzen, der die Erfassung durch Google Analytics innerhalb dieser Website zukünftig verhindert (dieses Opt-Out-Cookie funktioniert nur in diesem Browser und nur für diese Domain, löschen Sie Ihre Cookies in diesem Browser, müssen Sie diesen Link erneut klicken): &lt;a onclick="alert('Google Analytics wurde deaktiviert');" href="javascript:gaOptout()"&gt;Google Analytics deaktivieren&lt;/a&gt;

                  Für den Fall der Übermittlung von personenbezogenen Daten an die Google LLC. mit Sitz in den USA, hat sich Google LLC. für das us-europäische Datenschutzübereinkommen „Privacy Shield“ zertifiziert, welches die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet. Ein aktuelles Zertifikat kann hier eingesehen werden: https://www.privacyshield.gov/list

                  Diese Website nutzt des Weiteren die Funktion “demografische Merkmale” von Google Analytics. Hierdurch können Berichte erstellt werden, die Aussagen zu demografischen Daten wie Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google, dem Google-Display-Netzwerk, sowie aus Besucherdaten von Drittanbietern. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google Analytics wie sogleich dargestellt generell untersagen.

                  Die Deaktivierung können Sie mithilfe eines Browser-Plugins von Google (https://tools.google.com/dlpage/gaoptout?hl=de) vornehmen. Alternativ zum Browser-Plugin oder innerhalb von Browsern auf mobilen Geräten klicken Sie bitte auf den folgenden Link, um ein Opt-Out-Cookie zu setzen, der die Erfassung durch Google Analytics innerhalb dieser Website zukünftig verhindert (dieses Opt-Out-Cookie funktioniert nur in diesem Browser und nur für diese Domain, löschen Sie Ihre Cookies in diesem Browser, müssen Sie diesen Link erneut klicken): &lt;a onclick="alert('Google Analytics wurde deaktiviert');" href="javascript:gaOptout()"&gt;Google Analytics deaktivieren&lt;/a&gt;

                  Weitere Hinweise zu Google (Universal) Analytics finden Sie hier: https://support.google.com/analytics/answer/2838718?hl=de&amp;ref_topic=6010376

                  &nbsp;

                  8) Tools und Sonstiges

                  8.1 Google Maps

                  Auf unserer Website verwenden wir Google Maps (API) von Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland (“Google”). Google Maps ist ein Webdienst zur Darstellung von interaktiven (Land-)Karten, um geographische Informationen visuell darzustellen. Über die Nutzung dieses Dienstes wird Ihnen unser Standort angezeigt und eine etwaige Anfahrt erleichtert.

                  Bereits beim Aufrufen derjenigen Unterseiten, in die die Karte von Google Maps eingebunden ist, werden Informationen über Ihre Nutzung unserer Website (wie z.B. Ihre IP-Adresse) an Server von Google übertragen und dort gespeichert, hierbei kann es auch zu einer Übermittlung an die Server der Google LLC. in den USA kommen. Dies erfolgt unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. Google speichert Ihre Daten (selbst für nicht eingeloggte Nutzer) als Nutzungsprofile und wertet diese aus. Die Erhebung, Speicherung und die Auswertung erfolgen gemäß Art. 6 Abs. 1 lit.f DSGVO auf Basis der berechtigten Interessen von Google an der Einblendung personalisierter Werbung, Marktforschung und/oder der bedarfsgerechten Gestaltung von Google-Websites. Ihnen steht ein Widerspruchsrecht gegen die Bildung dieser Nutzerprofile zu, wobei Sie sich für dessen Ausübung an Google wenden müssen.

                  Für den Fall der Übermittlung von personenbezogenen Daten an die Google LLC. mit Sitz in den USA, hat sich Google LLC. für das us-europäische Datenschutzübereinkommen „Privacy Shield“ zertifiziert, welches die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet. Ein aktuelles Zertifikat kann hier eingesehen werden: https://www.privacyshield.gov/list

                  Wenn Sie mit der künftigen Übermittlung Ihrer Daten an Google im Rahmen der Nutzung von Google Maps nicht einverstanden sind, besteht auch die Möglichkeit, den Webdienst von Google Maps vollständig zu deaktivieren, indem Sie die Anwendung JavaScript in Ihrem Browser ausschalten. Google Maps und damit auch die Kartenanzeige auf dieser Internetseite kann dann nicht genutzt werden.

                  Die Nutzungsbedingungen von Google können Sie unter https://www.google.de/intl/de/policies/terms/regional.html einsehen, die zusätzlichen Nutzungsbedingungen für Google Maps finden Sie unter https://www.google.com/intl/de_US/help/terms_maps.html

                  Ausführliche Informationen zum Datenschutz im Zusammenhang mit der Verwendung von Google Maps finden Sie auf der Internetseite von Google („Google Privacy Policy“): https://www.google.de/intl/de/policies/privacy/

                  8.2 Google Web Fonts

                  Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts die von der Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland („Google“) bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browser-Cache, um Texte und Schriftarten korrekt anzuzeigen.

                  Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen, hierbei kann es auch zu einer Übermittlung von personenbezogener Daten an die Server der Google LLC. in den USA kommen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.

                  Für den Fall der Übermittlung von personenbezogenen Daten an die Google LLC. mit Sitz in den USA, hat sich Google LLC. für das us-europäische Datenschutzübereinkommen „Privacy Shield“ zertifiziert, welches die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet. Ein aktuelles Zertifikat kann hier eingesehen werden: https://www.privacyshield.gov/list

                  Weitere Informationen zu Google Web Fonts finden Sie unter https://developers.google.com/fonts/faq und in der Datenschutzerklärung von Google: https://www.google.com/policies/privacy/

                  &nbsp;

                  9) Rechte des Betroffenen

                  9.1 Das geltende Datenschutzrecht gewährt Ihnen gegenüber dem Verantwortlichen hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten umfassende Betroffenenrechte (Auskunfts- und Interventionsrechte), über die wir Sie nachstehend informieren:

                  - Auskunftsrecht gemäß Art. 15 DSGVO: Sie haben insbesondere ein Recht auf Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten, die Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezogenen Daten, die Empfänger oder Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer bzw. die Kriterien für die Festlegung der Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung, Widerspruch gegen die Verarbeitung, Beschwerde bei einer Aufsichtsbehörde, die Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftige Informationen über die involvierte Logik und die Sie betreffende Tragweite und die angestrebten Auswirkungen einer solchen Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche Garantien gemäß Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittländer bestehen;

                  - Recht auf Berichtigung gemäß Art. 16 DSGVO: Sie haben ein Recht auf unverzügliche Berichtigung Sie betreffender unrichtiger Daten und/oder Vervollständigung Ihrer bei uns gespeicherten unvollständigen Daten;

                  - Recht auf Löschung gemäß Art. 17 DSGVO: Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten bei Vorliegen der Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen. Dieses Recht besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;

                  - Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO: Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, solange die von Ihnen bestrittene Richtigkeit Ihrer Daten überprüft wird, wenn Sie eine Löschung Ihrer Daten wegen unzulässiger Datenverarbeitung ablehnen und stattdessen die Einschränkung der Verarbeitung Ihrer Daten verlangen, wenn Sie Ihre Daten zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, nachdem wir diese Daten nach Zweckerreichung nicht mehr benötigen oder wenn Sie Widerspruch aus Gründen Ihrer besonderen Situation eingelegt haben, solange noch nicht feststeht, ob unsere berechtigten Gründe überwiegen;

                  - Recht auf Unterrichtung gemäß Art. 19 DSGVO: Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Ihnen steht das Recht zu, über diese Empfänger unterrichtet zu werden.

                  - Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO: Sie haben das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen, soweit dies technisch machbar ist;

                  - Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3 DSGVO: Sie haben das Recht, eine einmal erteilte Einwilligung in die Verarbeitung von Daten jederzeit mit Wirkung für die Zukunft zu widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten unverzüglich löschen, sofern eine weitere Verarbeitung nicht auf eine Rechtsgrundlage zur einwilligungslosen Verarbeitung gestützt werden kann. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt;

                  - Recht auf Beschwerde gemäß Art. 77 DSGVO: Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt, haben Sie - unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs - das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des mutmaßlichen Verstoßes.

                  9.2 WIDERSPRUCHSRECHT

                  WENN WIR IM RAHMEN EINER INTERESSENABWÄGUNG IHRE PERSONENBEZOGENEN DATEN AUFGRUND UNSERES ÜBERWIEGENDEN BERECHTIGTEN INTERESSES VERARBEITEN, HABEN SIE DAS JEDERZEITIGE RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIESE VERARBEITUNG WIDERSPRUCH MIT WIRKUNG FÜR DIE ZUKUNFT EINZULEGEN.

                  MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN KÖNNEN, DIE IHRE INTERESSEN, GRUNDRECHTE UND GRUNDFREIHEITEN ÜBERWIEGEN, ODER WENN DIE VERARBEITUNG DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN DIENT.

                  WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN. SIE KÖNNEN DEN WIDERSPRUCH WIE OBEN BESCHRIEBEN AUSÜBEN.

                  MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.

                  &nbsp;

                  10) Dauer der Speicherung personenbezogener Daten

                  Die Dauer der Speicherung von personenbezogenen Daten bemisst sich anhand der jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels- und steuerrechtliche Aufbewahrungsfristen). Nach Ablauf der Frist werden die entsprechenden Daten routinemäßig gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind und/oder unsererseits kein berechtigtes Interesse an der Weiterspeicherung fortbesteht.
                </div>
                </div>
            </div>
            </div>
          </div> 
        </div>
      <!-- footer -->
      <combme-footer></combme-footer>
    </div>
</template>

<script>
import Footer from '../components/Footer.vue';

export default {
    mounted: function() {
        window.scrollTo(0, 0);
    },
    components: {
        'combme-footer': Footer
    },
    methods: {
        setLocale(locale){
            this.$i18n.locale = locale;
            this.isActive != this.isActive;
            this.$router.push({
                params: { lang: locale }
            }).catch(() => {})
        }
    }
}
</script>

<style scoped>
    .left-logo {
        height: 70px;
        width: auto;
    }

    .navbar{
        opacity: 1000;
    }

    .nav-link{
        color: #fff !important;
         font-weight: 600;
    }

    .nav-link:hover{
        cursor: pointer;
    }

    .flag{
        width: 45px !important;
    }

    @media only screen and (max-width: 768px) {
        .navbar {
            background: transparent linear-gradient(318deg, #606060 0%, #262626 100%) 0% 0% no-repeat padding-box;
            /* background: #262626; */
            height: auto;
        }
        .flag {
            width: 31px !important;
        }
    }
    .privacy-content {
      padding-top: 20vh
    }
    .nav-wrapper {
        background: transparent linear-gradient(318deg, #606060 0%, #262626 100%) 0% 0% no-repeat padding-box;
        height: 85px;
    }
    .content-wrpper {
        color: #fff;
        font: 'Montserrat' !important;
    }
    .de-en-flag {
      height: 80px;
    }

    .privacy-text {
      padding-bottom: 80px;
    }

    .panel, .panel-body {
        box-shadow: none;
    }

    .panel-title {
        background: #f4f4f4;
        border-radius: 2px;
    }

    .panel-group .panel-heading {
        padding: 0;
    }

    .panel-group .panel-heading div {
        display: block;
        padding: 10px 15px;
        text-decoration: none;
        position: relative;
    }

    .panel-group .panel-heading div:after {
        content: '-';
        float: right;
        position: relative;
        top: -60px
    }

    .panel-group .panel-heading div.collapsed:after {
        content: '+';
    }

    @media only screen and (max-width: 768px) {
        .de-en-flag {
            padding-bottom: 21px;
        }
        .privacy-text {
            padding-bottom: 50px;
        }
    }

</style>