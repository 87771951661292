<template>
    <nav class="navbar navbar-expand-sm fixed-top navbar-light bg-faded container">
        <img class="left-logo" src="../assets/images/logo.png" alt="COMBME">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar" aria-controls="#collapsingNavbar">
                    <span class="navbar-toggler-icon"></span>
                </button>
        <div class="collapse navbar-collapse" id="collapsingNavbar">
            <ul class="nav navbar-nav ml-auto w-100 justify-content-end" id="nav">
                <li class="nav-item" data-toggle="collapse" data-target="#collapsingNavbar">
                    <!-- <router-link :to="`/${$i18n.locale}/home`" href="#home" class="nav-link active" tag="a">{{ $t('home') }}</router-link> -->
                    <a class="nav-link active" href="#home">{{ $t('home') }}</a>
                </li>
                <li class="nav-item" data-toggle="collapse" data-target="#collapsingNavbar">
                    <a class="nav-link" href="#description">{{ $t('description') }}</a>
                </li>
                <li class="nav-item" data-toggle="collapse" data-target="#collapsingNavbar">
                    <a class="nav-link" href="#appoverview">{{ $t('appOverview') }}</a>
                </li>
                <li class="nav-item" data-toggle="collapse" data-target="#collapsingNavbar">
                    <a class="nav-link" href="#team">{{ $t('team') }}</a>
                </li>
                <li class="nav-item" data-toggle="collapse" data-target="#collapsingNavbar">
                    <a class="nav-link" href="#contactus" >{{ $t('contactus') }}</a>
                </li>
                <li class="nav-item" data-toggle="collapse" data-target="#collapsingNavbar">
                    <img class="nav-link flag" src="../assets/images/german_flag.png" @click.prevent="setLocale('de')">
                </li>
                <li class="nav-item" data-toggle="collapse" data-target="#collapsingNavbar">
                    <img class="nav-link flag" src="../assets/images/english_flag.png" @click.prevent="setLocale('en')">
                </li>    
            </ul>
        </div>
    </nav>
</template>

<script>
    export default {

        methods:{
            setLocale(locale){
                this.$i18n.locale = locale;
                this.isActive != this.isActive;
                this.$router.push({
                    params: { lang: locale }
                }).catch(() => {})
            },
            
        },
        mounted(){
            let header = document.getElementById("nav");
            let btns = header.getElementsByClassName("nav-link");
            for (let i = 0; i < btns.length; i++) {
                btns[i].addEventListener("click", function() {
                let current = document.getElementsByClassName("active");
                current[0].className = current[0].className.replace(" active", "");
                this.className += " active";
            });
            }
        }
    }
</script>

<style scoped>
    .left-logo {
        height: 70px;
        width: auto;
    }

    .navbar{
        opacity: 1000;
    }

    .nav-link{
        color: #fff !important;
         font-weight: 600;
    }

    .nav-link:hover{
        cursor: pointer;
    }

    .flag{
        width: 45px !important;
    }

    .active, .btn:hover {
        color: #32AADC !important;
        font-weight: 700;

    }

    @media only screen and (max-width: 768px) {
        .navbar{
            background: transparent linear-gradient(318deg, #606060 0%, #262626 100%) 0% 0% no-repeat padding-box;
            height: auto;
        }
        .flag{
            width: 31px !important;
            cursor: pointer;
        }
    }

</style>