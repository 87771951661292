<template>
    <div id="top">
        <div class="nav-wrapper fixed-top">
            <nav class="navbar navbar-expand-sm fixed-top navbar-light bg-faded container">
                <img class="left-logo" src="../assets/images/logo.png" alt="COMBME">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingImprintNavbar" aria-controls="#collapsingImprintNavbar">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="collapsingImprintNavbar">
                    <ul class="nav navbar-nav ml-auto w-100 justify-content-end" id="nav">
                        <li class="nav-item" data-toggle="collapse" data-target="#collapsingImprintNavbar">
                            <router-link :to="`/${$i18n.locale}/home`" class="nav-link active" tag="a">{{ $t('home') }}</router-link>
                        </li>
                        <li class="nav-item" data-toggle="collapse" data-target="#collapsingImprintNavbar">
                            <img class="nav-link flag" src="../assets/images/german_flag.png" @click.prevent="setLocale('de')">
                        </li>
                        <li class="nav-item" data-toggle="collapse" data-target="#collapsingImprintNavbar">
                            <img class="nav-link flag" src="../assets/images/english_flag.png" @click.prevent="setLocale('en')">
                        </li>    
                    </ul>
                </div>
            </nav>
        </div>
        <div class="imprint-content container">
          <div class="row imprint-text">
            <div class="col-lg-2">
              <img class="de-en-flag" src="../assets/images/privacy-en.png" alt="">
            </div>
            <div class="panel-group col-lg-10" id="accordionSingleClosed" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default ">
                    <div class="panel-heading" role="tab" id="headingOne">
                    <h4 class="panel-title">
                        <div class="collapsed" role="button" data-toggle="collapse" href="#collapseItemCloseOne" aria-expanded="true" aria-controls="collapseItemCloseOne">
                            <h3>ENGLISH / ENGLISCH</h3>
                            <h4>Imprint</h4>
                        </div>
                    </h4>
                    </div>
                    <div id="collapseItemCloseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div class="panel-body">
                            CombMe UG (limited liability) <br>
                            Backesweg 54 A <br>
                            63477 Maintal <br>
                            Germany <br>
                            <br>
                            Local Court 63450 Hanau <br>
                            Commercial Register Number HRB 98508 <br>
                            <br>
                            Phone number: 06181-4352341 <br>
                            Email: info@combme.com <br>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="row imprint-text">
            <div class="col-lg-2">
              <img class="de-en-flag" src="../assets/images/german-flag-privacy.png" alt="">
            </div>
            <div class="panel-group col-lg-10" id="accordionSingleClosed" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default ">
                    <div class="panel-heading" role="tab" id="headingTwo">
                    <h4 class="panel-title">
                        <div class="collapsed" role="button" data-toggle="collapse" href="#collapseItemCloseTwo" aria-expanded="true" aria-controls="collapseItemCloseTwo">
                            <h3>GERMAN / DEUTSCH</h3>
                            <h4>Impressum</h4>
                        </div>
                    </h4>
                    </div>
                    <div id="collapseItemCloseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                        <div class="panel-body">
                            CombMe UG (haftungsbeschränkt) <br>
                            Backesweg 54 A63477 Maintal <br>
                            Deutschland <br>
                            <br>
                            Amtsgericht 63450 Hanau <br>
                            Handelsregister-Nummer HRB 98508 <br>
                            <br>
                            Telefonnummer: 06181-4352341 <br>
                            Email: info@combme.com <br>
                        </div>
                    </div>
                </div>
            </div>
          </div> 
        </div>
      <!-- footer -->
      <combme-footer></combme-footer>
    </div>
</template>

<script>
import Footer from '../components/Footer.vue';

export default {
    mounted: function() {
        window.scrollTo(0, 0);
    },
    components: {
        'combme-footer': Footer
    },
    methods: {
        setLocale(locale){
            this.$i18n.locale = locale;
            this.isActive != this.isActive;
            this.$router.push({
                params: { lang: locale }
            }).catch(() => {})
        }
    }
}
</script>

<style scoped>
    .left-logo {
        height: 70px;
        width: auto;
    }

    .navbar{
        opacity: 1000;
    }

    .nav-link{
        color: #fff !important;
         font-weight: 600;
    }

    .nav-link:hover{
        cursor: pointer;
    }

    .flag{
        width: 45px !important;
    }

    @media only screen and (max-width: 768px) {
        .navbar {
            background: transparent linear-gradient(318deg, #606060 0%, #262626 100%) 0% 0% no-repeat padding-box;
            /* background: #262626; */
            height: auto;
        }
        .flag {
            width: 31px !important;
        }
    }
    .imprint-content {
      padding-top: 20vh
    }
    .nav-wrapper {
        background: transparent linear-gradient(318deg, #606060 0%, #262626 100%) 0% 0% no-repeat padding-box;
        height: 85px;
    }
    .content-wrpper {
        color: #fff;
        font: 'Montserrat' !important;
    }
    .de-en-flag {
      height: 80px;
    }

    .imprint-text {
      padding-bottom: 80px;
    }

    .panel, .panel-body {
        box-shadow: none;
    }

    .panel-title {
        background: #f4f4f4;
        border-radius: 2px;
    }

    .panel-group .panel-heading {
        padding: 0;
    }

    .panel-group .panel-heading div {
        display: block;
        padding: 10px 15px;
        text-decoration: none;
        position: relative;
    }

    .panel-group .panel-heading div:after {
        content: '-';
        float: right;
        position: relative;
        top: -60px
    }

    .panel-group .panel-heading div.collapsed:after {
        content: '+';
    }

    @media only screen and (max-width: 768px) {
        .de-en-flag {
            padding-bottom: 21px;
        }
        .imprint-text {
            padding-bottom: 50px;
        }
    }

</style>